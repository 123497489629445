/* Centralizar o container de login vertical e horizontalmente */
.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #1a1a1a;
    /* Fundo escuro */
}

/* Estilização do formulário de login */
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    background-color: #333;
    /* Cor de fundo do formulário */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
}

/* Estilização do título do formulário */
.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #f0f0f0;
    /* Cor do texto */
}

/* Estilização dos campos de input */
.login-form input {
    width: 100%;
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #555;
    /* Bordas cinza escuro */
    border-radius: 5px;
    background-color: #444;
    /* Fundo do input */
    color: #f0f0f0;
    /* Cor do texto */
    box-sizing: border-box;
}

/* Estilização do botão de login */
.login-button {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    background-color: #007bff;
    /* Cor azul para o botão */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.login-button:hover {
    background-color: #0056b3;
    /* Cor azul escuro ao passar o mouse */
}

/* Mensagem de erro */
.error-message {
    color: #ff4c4c;
    /* Vermelho para erros */
    margin-bottom: 10px;
    text-align: center;
    font-size: 14px;
}