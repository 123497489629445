/* src/ChatUI.css */
body {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
    background-color: #121212;
    color: #e0e0e0;
}

.chat-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100vh;
    width: 45%;
    /* Aumentando a largura do chat */
    margin: 0 auto;
    /* Centralizando o chat */
    background-color: #121212;
    position: relative;
}

.mode-toggle {
    position: absolute;
    top: 10px;
    left: 10px;
    background: linear-gradient(145deg, #6b6b6b, #1f1f1f);
    color: #e0e0e0;
    border: none;
    padding: 8px 20px;
    border-radius: 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.mode-toggle:hover {
    background: linear-gradient(145deg, #7b7b7b, #2f2f2f);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.3);
}

.mode-toggle:active {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
}


.message-container {
    width: 100%;
    margin-bottom: 80px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.message {
    background-color: #1f1f1f;
    color: #e0e0e0;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    /* Reduzindo a margem inferior para aproximar as mensagens */
    max-width: 60%;
    word-wrap: break-word;
}

.user-message {
    align-self: flex-end;
    background-color: #3a3a3a;
    text-align: right;
    margin-left: auto;
    margin-right: 5px;
    /* Reduzindo a margem à direita */
}

.system-message {
    align-self: flex-start;
    background-color: #2a2a2a;
    text-align: left;
    margin-right: auto;
    margin-left: 5px;
    /* Reduzindo a margem à esquerda */
}

.input-form {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
    background-color: #1f1f1f;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
}

.text-input {
    width: 80%;
    max-width: 600px;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #333;
    background-color: #333;
    color: #e0e0e0;
    font-size: 16px;
}

.input-reset-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.text-input::placeholder {
    color: #888;
}

.reset-button {
    margin-right: 10px;
    /* Espaço entre o botão e o input */
    padding: 8px 20px;
    font-size: 16px;
    font-weight: bold;
    background: linear-gradient(145deg, #6b6b6b, #1f1f1f);
    /* Gradiente com cinza e laranja */
    color: white;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3), 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
}

.reset-button:hover {
    background: linear-gradient(145deg, #7b7b7b, #2f2f2f);
    /* Gradiente com cinza mais claro e laranja */
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.4), 0 3px 5px rgba(0, 0, 0, 0.3);
}

.reset-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3), 0 1px 2px rgba(0, 0, 0, 0.2);
}

/* Estilo para o botão de logout */
.logout-button {
    padding: 10px 20px;
    font-size: 14px;
    background-color: #d9534f;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    transition: background-color 0.3s;
}

.logout-button:hover {
    background-color: #c9302c;
}